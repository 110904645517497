//Libs
import React, { useState } from 'react';
//Utils
import { getHumanize } from 'utils/libs/dateFormats';
import KEYWORDS from 'components/Collector/keywords';
//Hooks
import { useTheme } from 'hooks';
//Components
import { PDFIcon, RefreshIcon, ExcelIcon } from 'components/Icons';
//Styles
import styles from './ReportCard.module.css';
import ApiService from 'services/apiService/apiService';
import { Button, Menu, Popover, Tooltip } from 'components';

const ReportName = ({ name }) => (
	<span
		style={{
			display: 'block',
			fontWeight: 'bold',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			maxWidth: '250px',
		}}
	>
		{name}
	</span>
);

/**
 * @param {int} otdId - Order transaction doc ID
 * @param {boolean} isMergePdf - Show if can merge PDF
 * @param {Object} reportName - Name of the report
 * @returns
 */
const IconReport = ({ reportName }) => {
	const { theme } = useTheme();
	return (
		<div className={styles.sectionIcons}>
			<div style={{ color: theme._primaryColor, paddingLeft: '10px' }}>
				<ReportName name={reportName} />
			</div>
		</div>
	);
};

/**
 * @param {Object} report - Service task report
 * @param {int} otdId - Order transaction doc ID
 * @param {string} odtId - External & Presentational order to work ID
 * @param {function} sendToast - Show message to user action
 * @returns
 */
const ActionButtons = ({ report, otdId, odtId, sendToast }) => {
	const {
		id: reportId,
		updatedAt,
		pdfReportUrl,
		xlsxReportUrl,
		hasSqlPdfReport,
		hasSqlXlsxReport,
	} = report;
	const [showPopover, setShowPopover] = useState(false);

	const messageToast = () =>
		sendToast({
			message:
				'Solicitud recibida... Cuando esté listo, encontrará el reporte en la bandeja de notificaciones',
			duration: 8,
		});

	const handleCreateReport = format => {
		ApiService.fetch(ApiService.endpoints().GENERATE_REPORT, {
			bodyParams: {
				odtId,
				otdId,
				serviceTaskId: reportId,
				format,
			},
		}).then(messageToast);
		setShowPopover(false);
	};

	const SelectionReportGenerate = (
		<Menu.Wrapper>
			{hasSqlXlsxReport && (
				<Menu.Item key='generatexlsx'>
					<Button
						onClick={() =>
							handleCreateReport(KEYWORDS.REPORTS.OUTPUT_FORMATS.XLSX)
						}
					>
						Generar XLSX
					</Button>
				</Menu.Item>
			)}
			{hasSqlPdfReport && (
				<Menu.Item key='generatepdf'>
					<Button
						onClick={() =>
							handleCreateReport(KEYWORDS.REPORTS.OUTPUT_FORMATS.PDF)
						}
					>
						Generar PDf
					</Button>
				</Menu.Item>
			)}
		</Menu.Wrapper>
	);

	return (
		<div className={styles.actionButtons}>
			{!!xlsxReportUrl && (
				<a href={xlsxReportUrl} target='_blank'>
					<Tooltip title={updatedAt ? getHumanize({ updatedAt }) : ''}>
						<div className={styles.iconWrapper}>
							<ExcelIcon button />
						</div>
					</Tooltip>
				</a>
			)}
			{!!pdfReportUrl && (
				<a href={pdfReportUrl} target='_blank'>
					<Tooltip title={updatedAt ? getHumanize({ updatedAt }) : ''}>
						<div className={styles.iconWrapper}>
							<PDFIcon button />
						</div>
					</Tooltip>
				</a>
			)}
			<div className={styles.iconWrapper}>
				<Popover
					content={SelectionReportGenerate}
					placement='top'
					title={null}
					trigger='click'
					visible={showPopover}
					onVisibleChange={() => setShowPopover(!showPopover)}
				>
					<div>
						<RefreshIcon button />
					</div>
				</Popover>
			</div>
		</div>
	);
};

/**
 * @param {Object} report - Service task report
 * @param {int} otdId - Order transaction doc ID
 * @param {string} odtId - External & Presentational order to work ID
 * @param {function} sendToast - Show message to user action
 * @returns
 */
const ReportCard = ({ report, otdId, odtId, sendToast }) => {
	const [isHovered, setIsHovered] = useState(false);
	const { theme } = useTheme();

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={{
				backgroundColor: isHovered
					? theme._secondaryBackgroundColor
					: theme._primaryBackgroundColor,
			}}
			className={styles.postWrapper}
		>
			<IconReport reportName={report.name} />

			<ActionButtons
				report={report}
				otdId={otdId}
				odtId={odtId}
				sendToast={sendToast}
			/>
		</div>
	);
};

export default ReportCard;
