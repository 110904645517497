// Libs
import React from 'react';
import _ from 'underscore';
// Utils
import GENERAL from 'utils/constants/general';
import { filterWarehouseList } from 'utils/filters/Warehouses/warehouseList';
// Components
import { Modal } from 'screens/Utils';
import {
	getMyEntityActiveUsers,
	getSpecificUsers,
	getMyEntityWarehouses,
	getConsolidateWarehouses,
	filterWarehousesSearchInData,
} from 'utils/libs';
// import { Wrapper, Flag, Span, Link } from 'components';
import Wrapper from 'components/Wrapper/Wrapper';
import Flag from 'components/Flag';
import Span from 'components/Span/Span';
import Link from 'components/Link';
import TableVirtualized from 'components/TableVirtualized';
// Styles
import '../../css/WarehousesList.css';

const { ENV } = GENERAL;
const { Table, Column, Header, Row, Cell } = TableVirtualized;

function WarehousesListDesktop(props) {
	const {
		control,
		stock,
		createWarehouse,
		profile,
		mutate1Object,
		mutate1ObjectApi,
		handleOnClickCreateWarehouse,
		getStockArticles,
	} = props;
	const users = getMyEntityActiveUsers(props.users.data, profile);
	// const experts = getSpecificUsers(users, { experts: true });
	const entities = getSpecificUsers(users, { entities: true });
	const allWarehouses = [...props.warehouses.data];

	let warehouses = allWarehouses;
	warehouses = getMyEntityWarehouses(props.warehouses.data, profile);
	if (control.mode === 'consolidate') {
		warehouses = getConsolidateWarehouses(entities);
	}
	warehouses = filterWarehousesSearchInData(
		warehouses,
		control.searchInData.searchBy,
		control.searchInData.data,
	);
	// Filters table
	warehouses = filterWarehouseList(warehouses, control.filters);

	// Functions
	function getHeaderRenderer({ dataKey, label }, orderType) {
		return (
			<Header
				filter={{
					dataKey,
					data: allWarehouses,
					orderType,
					dateFormat: profile.user.settings.date_format,
					filters: control.filters,
					onChangedFilter: updatedFilters =>
						mutate1Object('control', {
							filters: updatedFilters,
						}),
				}}
			>
				{label}
			</Header>
		);
	}

	function getIndexCellRenderer({ rowData, rowIndex }) {
		let flagPriorityStyle = {};

		if (rowData.priority) flagPriorityStyle = { ...rowData.priority.style };
		return (
			<Cell>
				<Flag style={flagPriorityStyle} height='20px' />
				<Span margin='0 0 0 5px'>{rowIndex + 1}</Span>
			</Cell>
		);
	}

	function getNameCellRenderer({ rowData }) {
		return (
			<Link
				to={ENV.ROUTES.PATHS.WAREHOUSES_STOCK_ARTICLES_DESKTOP}
				onClick={() => {
					const ownerId = control.mode === 'individual' ? rowData.entity_id : rowData.owner_id;
					mutate1ObjectApi('stock', {
						...stock,
						warehouseId: rowData.id,
						ownerId,
					});
					getStockArticles(rowData.id, ownerId, control.mode);
				}}
			>
				<div>{rowData.name}</div>
			</Link>
		);
	}

	function getRowRenderer({ key, columns, style, rowData }) {
		const selectedWarehouses = [...control.selectedWarehouses];
		let styleRow = { ...style };

		// Is warehouse selected?
		const idx = _.findIndex(
			selectedWarehouses,
			warehouseName => warehouseName === rowData.name,
		);
		if (idx !== -1) {
			styleRow = {
				...styleRow,
				color: 'lightseagreen',
				backgroundColor: 'rgb(44,44,54)',
			};
		}

		return (
			<Row key={key} style={styleRow}>
				{columns}
			</Row>
		);
	}

	// Render
	return (
		<Wrapper padding='0' className='animated fadeIn'>
			<Table
				highBackground
				width={document.documentElement.clientWidth}
				height={document.documentElement.clientHeight - 115}
				headerHeight={44}
				rowHeight={40}
				rowCount={warehouses.length}
				rowGetter={({ index }) => warehouses[index]}
				rowRenderer={getRowRenderer}
			>
				<Column
					dataKey='index'
					label='#'
					width={70}
					headerRenderer={({ label }) => <Header>{label}</Header>}
					cellRenderer={getIndexCellRenderer}
				/>
				<Column
					dataKey='name'
					label='Almacén'
					width={250}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
					cellRenderer={getNameCellRenderer}
				/>
				<Column
					dataKey='owner_name'
					label='Propietario'
					width={250}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='entity_name'
					label='Entidad'
					width={220}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='type'
					label='Tipo'
					width={100}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='country'
					label='País'
					width={130}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='address'
					label='Dirección'
					width={200}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='creator_name'
					label='Creador'
					width={200}
					headerRenderer={props => getHeaderRenderer(props, 'text')}
				/>
				<Column
					dataKey='created_at'
					label='Creado'
					width={140}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
				<Column
					dataKey='updated_at'
					label='Actualizado'
					width={140}
					headerRenderer={props => getHeaderRenderer(props, 'date')}
				/>
			</Table>

			<Modal
				modalId='CreateWarehouseModal'
				title='Nuevo Almacén'
				visible={createWarehouse.isOpen}
				onCancel={() => mutate1Object('createWarehouse', { isOpen: false })}
				footer={null}
				isSpinning={createWarehouse.loading}
				sizeSpinning='large'
				delaySpinning={50}
				tipSpinning='Un momento porfavor...'
				customProps={{
					entities,
					createWarehouse,
					profile,
					mutate1Object,
					handleOnClickCreateWarehouse,
				}}
			/>
		</Wrapper>
	);
}

export default WarehousesListDesktop;
